import React from 'react';
import { Row, Col, Table } from "reactstrap";

function InvTotal({ data }) {
    data["combinedKeys"] = [...new Set([...Object.keys(data.ekosTotal || {}), ...Object.keys(data.kegshoeTotal || {}), ...Object.keys(data.deliveriesTotal || {})])];
    // To use month name instead of number, using number as index
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];
    const date = new Date();

    return (
        <>
        {data ? 
                <Row>
                    <Col xs={{ offset:1, size: 10}}>
                        <Col xs="12">
                            <h4>
                                {`${(window.location.href.split("/").at(-1) === "tomorrow") ? date.getDate() + 1: date.getDate()} ${monthNames[date.getMonth()]} ${date.getFullYear()}`}
                            </h4>
                        </Col>
                        <Table striped={true} size="sm" responsive={true} style={{ "white-space": "pre-line" }}>
                            <thead>
                                <tr>
                                    <th>
                                        title
                                    </th>
                                    <th>
                                        ekos
                                    </th>
                                    <th>
                                        kegshoe
                                    </th>
                                    <th>
                                        delivery
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.combinedKeys?.map((key) => (
                                    <tr key={key}>
                                        <td>{key}</td>
                                        <td>{data.ekosTotal?.[key] ?? "0"}</td>
                                        <td>{data.kegshoeTotal?.[key] ?? "0"}</td> 
                                        <td>{data.deliveriesTotal?.[key] ?? ""}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row> : null
        }

        </>
    )
}

export default InvTotal;