import {React, useState, useEffect} from 'react';
import axios from 'axios';
import { Row, Col, Table, Input } from "reactstrap";
import MainNavbar from './Navbar';

function DeliveryTable() {
    document.title = `My Brewery - Delivery`
    const [data, setData] = useState(null);
    const [err, setErr] = useState("Collecting information")
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];
    const date = new Date();

    const sendChange = (name, column) => {
        axios.post("/set-table-change", {name: column})
    }

    function fetchData() {
        axios.get("/get-orders").then((res) => {
            setData(res.data);
            setErr(null);
        }).catch(err => {
            setErr(err.message);
            setData(null)
        })
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <>
        <MainNavbar />
        {err? 
            <Row className='mt-1'>
                <Col md={{offset: 5, size: 5}}sm={{offset: 4, size: 5}} xs={{offset: 1, size: 11}}>
                    <h6>{err}</h6>
                </Col>
            </Row> : null}
        {data ? 
                <Row>
                    <Col xs={{ offset:1, size: 10}}>
                        <Col xs="12">
                            <h4>
                                {`${date.getDate()} ${monthNames[date.getMonth()]} ${date.getFullYear()}`}
                            </h4>
                        </Col>
                        <Table striped={true} responsive={true} bordered={true} 
                        style={{ "white-space": "pre-line", "border": "1px solid black"}}>
                            <thead>
                                <tr>
                                    <th style={{"width": 2+"vw"}}>
                                        N
                                    </th>
                                    <th style={{"width": 2+"vw"}}>
                                        R
                                    </th>
                                    <th style={{"width": 2+"vw"}}>
                                        I 
                                    </th>
                                    <th>
                                        CUSTOMER
                                    </th>
                                    <th style={{"width": 3+"vw"}}>
                                        C
                                    </th>
                                    <th>
                                        ORDER
                                    </th>
                                    <th style={{"width": 4+"vw"}}>
                                        Note
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(data).map((key, index) => (
                                    <tr key={index}>
                                        <td style={{"text-align": "center"}}>{index + 1}</td>
                                        <td className="p-0" style={{"vertical-align": "baseline"}}>
                                            <Input type="text" bsSize="sm" className="my-1 p-0" maxlength="2"
                                            style={{"font-size": 1+"em", "height": 100+"%", "textAlign": "center"}}
                                            value={data[key][1] == 0? "": data[key][1]}
                                            onChange={(e) => {
                                                const newData = { ...data };
                                                newData[key][1] = e.target.value;
                                                setData(newData);
                                                axios.post("/set-table-change", [key, "r", e.target.value])
                                            }} />
                                        </td>
                                        <td>
                                            <Input type="checkbox" checked={data[key][2]}
                                            onClick={(e) => {
                                                const newData = { ...data };
                                                newData[key][2] = e.target.checked;
                                                setData(newData);
                                                axios.post("/set-table-change", [key, "i", e.target.checked])
                                            }}/>
                                        </td>
                                        <td>{key}</td>
                                        <td>   </td>
                                        <td>{data[key][0]}</td>
                                        <td style={{"textAlign": "center"}}>{data[key][3]? "+": ""}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row> : null
        }

        </>
    )
}

export default DeliveryTable;