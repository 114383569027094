import React, {useState, useEffect} from 'react';
import { Row, Col, Table} from 'reactstrap';
import axios from 'axios';
import MainNavbar from './Navbar';


function Weekly() {
    document.title = `My Brewery - Weekly`
    const [data, setData] = useState(null);
    const [err, setErr] = useState("Collecting information...")
    const currentYear = new Date().getFullYear();

    useEffect(() => {
        axios.get("/get_weekly").then((res) => {
            setData(res.data);
            setErr(null);
        }).catch((err) => setErr(err.message));
    }, [])

    return (
        <>
            <MainNavbar />
            {err ? 
            <>
                <Row className='mt-1'>
                    <Col md={{offset: 5, size: 5}}sm={{offset: 4, size: 5}} xs={{offset: 1, size: 11}}>
                        <h2>{err}</h2>
                    </Col>
                </Row> 
            </> : 
            <Row>
                <Col xs={{ offset:1, size: 10}}>
                    <Table striped={true} size="lg" responsive={true} style={{ "white-space": "pre-line" }}>
                        <thead>
                            <tr>
                                <th>
                                    Beer
                                </th>
                                <th>
                                    {currentYear}
                                </th>
                                <th>
                                    {currentYear - 1}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.keys?.map(key => (
                                                <tr key={key}>
                                                    <td>{key}</td>
                                                    <td>{data.curr[key] ?? "0"}</td>
                                                    <td>{data.prev[key] ?? "0"}</td> 
                                                </tr>
                                                ))
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
            }
        </>
    )
}

export default Weekly;